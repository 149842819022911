import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import Database from '../../../../Constant/database.json'
import ImageUpload1 from "../../../ImageUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import ImageUpload from "./ImageUpload1";
import AudioUpload from "./AudioUpload";

const QuestionBlock = [
  "question_number",
  "question",
  "question_description",
  "question_description_type",
  "question_media",
  "question_media_type",
  "option_1",
  "option_2",
  "option_3",
  "option_4",
  "answer_description",
  "correct_answer",
  "answer_media_type",
]



const OptionView = ({ data_type, value, index, type, placeholder, que, onChangeFunction }) => {
  return (
    <>
      {data_type == "text" &&
        <>
          {que ?
            <textarea rows={3}
              className="p-0 px-2 col-12 input-common-121"
              value={value}
              onChange={(event) => {
                onChangeFunction(type, event.target.value, index)
              }}
              placeholder={placeholder}
            />
            :
            <input
              className="input-common-121"
              value={value}
              onChange={(event) => {
                onChangeFunction(type, event.target.value, index)
              }}
              placeholder={placeholder}
            />
          }
        </>
      }
      {data_type == "image" &&
        <ImageUpload
          ImageUrl={value}
          onChange={onChangeFunction}
          type={type}
          index={index}
        />
      }
      {data_type == "audio" &&
        <AudioUpload
          ImageUrl={value}
          onChange={onChangeFunction}
          type={type}
          index={index}
        />
      }
    </>
  )
}

export default function FormComponent(props) {
  const { FetchData, db_slug, webSlug } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [ExamSlug, setSlug] = React.useState(FetchData.slug)
  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Time, setTime] = React.useState(FetchData.meta.exam_time)
  const [Details, setDetails] = React.useState(FetchData.meta.exam_details)
  const [Available, setAvaliable] = React.useState(FetchData.meta.available_unavailable)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [ExamType, setExamType] = React.useState(FetchData.meta.exam_type)
  const [CopyTo, setCopyTo] = React.useState("")
  const [AudioSingle, setAudioSingle] = React.useState(FetchData.meta.media_non_repeat)

  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(
    db_slug == "demo_exam" ?
      JSON.parse(FetchData.meta.exam_question_text)
      :
      JSON.parse(FetchData.meta.exam_question_block_text)
  );
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();
  const [slugValue, setExamSlug] = React.useState(FetchData.slug)
  const [AddMultipleQues, setAddMultipleQues] = React.useState(false);

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [ExamPrice, setExamPrice] = React.useState(FetchData.meta.exam_price)
  const [ExamResetPrice, setExamResetPrice] = React.useState(db_slug == slugs.db_slug_paid_exam ? FetchData.meta.exam_reset_price : "")

  const EditInfo = (POSTID, slug, passSlug) => {
    setReload(true)
    console.log(QuestionsData)
    var data;
    if (slug == "daily_exam")
      data = {
        "title": Title,
        "status": "publish",
        "slug": ExamSlug,
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "batch_id": batchID,
          "batch_name": batchName,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }
    else if (slug == "demo_exam")
      data = {
        "title": Title,
        "status": "publish",
        "slug": ExamSlug,
        "meta": {
          "exam_question_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }
    else if (slug == slugs.db_slug_question_bank)
      data = {
        "title": Title,
        "status": "publish",
        // "slug": slugValue,
        "slug": ExamSlug,
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType,
          "exam_category": typeName,
          "exam_category_id": TypeID,
          "exam_price": ExamPrice
        }
      }
    else
      data = {
        "title": Title,
        "status": "publish",
        // "slug": slugValue,
        "slug": ExamSlug,
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType,
          "exam_price": ExamPrice
        }
      }

    if (POSTID === "")
      AdsPOSTApi(data, slug)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Exam Added Successfully")
          console.log(res.data)
          history.push(`${passSlug}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slug, POSTID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
  const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])

  useEffect(() => {
    if (db_slug == "daily_exam")
      BatchList()
    if (db_slug == slugs.db_slug_question_bank)
      CategoriesList()
  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const CategoriesList = () => {
    AdsGETApiAuth(
      { per_page: 1000, _fields: "id,title,meta" },
      slugs.db_slug_exam_categories
    )
      .then((res) => {
        setTypeList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const [TypeID, setTypeID] = React.useState(FetchData.meta.exam_category_id)
  const [typeName, setTypeName] = React.useState(FetchData.meta.exam_category)
  const [TypeModal, setTypeModal] = React.useState(false)
  const [TypeList, setTypeList] = React.useState([])
  const [toastActive, setToastActive] = React.useState(false)
  const [toastText, setToastText] = React.useState("")

  const onChangeFunction = (key, value, index) => {
    var changeValue = {
      ...Object.values(QuestionsData)[index],
      [key]: value
    }
    var AllChange = {
      ...QuestionsData,
      [Object.keys(QuestionsData)[index]]: changeValue
    }
    setQuestionsData(AllChange)

  }
  const questype = [
    "none",
    "text",
    "image",
    "audio"
  ]

  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam Information</center></h4>

          <table>
            <tbody>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => {
                      setTitle(event.target.value)
                      if (ID == "")
                        setSlug(event.target.value
                          .toLowerCase()
                          .trim()
                          .replace(/[^\w\s-]/g, '')
                          .replace(/[\s_-]+/g, '-')
                          .replace(/^-+|-+$/g, ''))
                      console.log(event.target.value)
                    }}
                  />
                </td>
              </tr>
              {/* {ExamSlug} */}

              <tr>
                <td className="col-3">Exam Time (in minutes)</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Exam Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Details</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common"
                    value={Details} placeholder="Exam Details"
                    onChange={(event) => { setDetails(event.target.value) }}
                  />
                </td>
              </tr>
              {db_slug !== "daily_exam" &&
                <>
                  <tr>
                    <td className="col-3">Exam Price</td>
                    <td className="col-6">
                      <input className="input-common"
                        value={ExamPrice} placeholder="Exam Price"
                        onChange={(event) => { setExamPrice(event.target.value) }}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="col-3">Exam Reset Price</td>
                    <td className="col-6">
                      <input className="input-common"
                        value={ExamResetPrice} placeholder="Exam Reset Price"
                        onChange={(event) => { setExamResetPrice(event.target.value) }}
                      />
                    </td>
                  </tr> */}
                </>
              }
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload1
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Audio Single</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    <div style={{ display: "inline-block" }}>
                      <CustomButtonUnSelect setData={setAudioSingle} Data={AudioSingle} DataValue={"Single"} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Available"} />
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Unavailable"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Type</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    {Database.exam_type.map((item, index) => (
                      <div style={{ display: "inline-block" }} key={index}>
                        <CustomButton setData={setExamType} Data={ExamType} DataValue={item} />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Category</td>
                <td className="col-6"
                  onClick={() => {
                    setTypeModal(!TypeModal)
                  }}
                >
                  <div className="input-common">
                    {typeName}&nbsp;
                  </div>
                  {/* <input className="input-common"
                    value={batchName} placeholder="Batch Name"
                    onChange={(event) => { setBatchName(event.target.value) }}
                  /> */}
                  {TypeModal &&
                    <section style={{ position: "absolute", padding: "10px" }}>
                      {TypeList.map((item, index) => (
                        <div className="row bg-white shadow" key={index}
                          style={{ cursor: "pointer", border: "1px solid #999" }}
                          onClick={() => {
                            setTypeID(`${item.id}`)
                            setTypeName(item.title.rendered)
                            setTypeModal(!TypeModal)
                          }}
                        >
                          <div className="col-1">
                            {TypeID != item.id ?
                              <i className="fa fa-square-o" />
                              :
                              <i className="fa fa-check-square-o" />
                            }
                          </div>
                          <div className="col-3">
                            {item.id}
                          </div>
                          <div className="col-6">
                            {item.title.rendered}
                          </div>
                        </div>
                      ))}
                    </section>
                  }
                </td>
              </tr>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo(ID, db_slug, webSlug)
                }}
              >
                Update Exam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Questions</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
                setAddMultipleQues(false)
              }}
            >
              Block
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(false)
                setAddMultipleQues(false)
              }}
            >
              Table
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "0px 5px", color: AddMultipleQues ? "#000000" : "#444444", fontWeight: AddMultipleQues ? "bold" : "normal" }}
              onClick={() => {
                setAddMultipleQues(true)
              }}
            >
              Add Multiple
            </div>
          </div>

          {!AddMultipleQues ?
            <>
              {(db_slug == slugs.db_slug_daily_exam || db_slug == slugs.db_slug_chapter_wise_exam || db_slug == slugs.db_slug_question_bank) &&
                <div>
                  <div className="row center m-2">
                    <div className=" col-3" style={{ padding: "0 10px" }}>
                      <div className="sign-in-button-4"
                        onClick={() => {
                          setQuestionsEditData({
                            "question_number": `${Object.keys(QuestionsData).length + 1}`,
                            "question": "",
                            "question_description": "",
                            "question_description_type": "text",
                            "question_media": "",
                            "question_media_type": "none",
                            "option_1": "",
                            "option_2": "",
                            "option_3": "",
                            "option_4": "",
                            "answer_description": "",
                            "correct_answer": "option 1",
                            "answer_media_type": "text",
                          })
                          setQuestionsEditKey(`item-${Object.keys(QuestionsData).length}`)
                          setQuestionsEditMode(true)
                        }}
                      >
                        Add Question
                      </div>
                    </div>
                  </div>
                </div>
              }
              {QuestionsBlock ?
                <div>
                  <div className="row m-4">
                    <div className="col-6">
                      <div className="row">
                        {Object.values(QuestionsData).map((item, index) => {
                          if (index < Object.values(QuestionsData).length / 2)
                            return (
                              <div key={index} className="col-2 p-2 center"
                                style={{
                                  margin: "5px",
                                  backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                                  fontWeight: "bold",
                                  border: "2px solid black",
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setQuestionsEditData(item)
                                  setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                  setQuestionsEditMode(true)
                                }}
                              >
                                {item.question_number}
                              </div>
                            )
                        })}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        {Object.values(QuestionsData).map((item, index) => {
                          if (index >= Object.values(QuestionsData).length / 2)
                            return (
                              <div key={index} className="col-2 p-2 center"
                                style={{
                                  margin: "5px",
                                  backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                                  fontWeight: "bold",
                                  border: "2px solid black",
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setQuestionsEditData(item)
                                  setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                  setQuestionsEditMode(true)
                                }}
                              >
                                {item.question_number}
                              </div>
                            )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div style={{
                  margin: "20px",
                  // display: "none",
                }}
                >
                  <div style={{ margin: "20px" }}>
                    <div className="col-12 form-view mb-3 ques-print" style={{ padding: "20px" }}>
                      <div style={{ overflowX: "scroll", }}>
                        {Object.values(QuestionsData).map((oneQuestionData, index) => (
                          <section key={index} style={{
                            pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                          }}
                            onClick={() => {
                              setQuestionsEditData(oneQuestionData)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            <div>
                              <div className="row"
                                style={{
                                  border: "1px solid #999",
                                  borderRadius: "5px",
                                  margin: "5px",
                                  padding: 0
                                }}>
                                <div className="col-md-8" >
                                  <div>
                                    <div style={{ padding: "5px 0 0 0" }}>
                                      <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                        {oneQuestionData.question}
                                      </h3>
                                      <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                        {oneQuestionData.question_number}.
                                      </h3>
                                    </div>
                                    <div className="question-description">
                                      {oneQuestionData.question_description_type == "text" &&
                                        <div style={{
                                          fontSize: "15px",
                                          padding: "0 0 15px"
                                        }}>
                                          <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                        </div>
                                      }
                                      {oneQuestionData.question_description_type == "image" &&
                                        <>
                                          <div style={{ padding: "2px", textAlign: "center", }} >
                                            <img
                                              src={oneQuestionData.question_description}
                                              style={{
                                                height: "150px",
                                                width: "392px",
                                                objectFit: 'contain',
                                                padding: "2px",
                                                border: "0.5px solid #999",
                                              }}
                                            />
                                          </div>
                                        </>
                                      }
                                      {oneQuestionData.question_description_type == "audio" &&
                                        <div>
                                          <audio controls>
                                            {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                            <source src={`${oneQuestionData.question_description}`} type="audio/mpeg" />
                                            <source src={`${oneQuestionData.question_description}`} type="audio/mp3" />
                                            <source src={`${oneQuestionData.question_description}`} type="audio/wav" />
                                            <source src={`${oneQuestionData.question_description}`} type="audio/ogg" />
                                            Your browser does not support the audio element.
                                          </audio>
                                          {/* <a href={oneQuestionData.question_description} target="_blank">
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                        }}>
                                          <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                            style={{ width: "20px", height: "20px" }}
                                          />
                                        </div>
                                      </a> */}
                                        </div>
                                      }
                                    </div>


                                    {oneQuestionData.question_media !== "" &&
                                      <div className="question-description col-10">
                                        {oneQuestionData.question_media_type == "text" &&
                                          <div style={{
                                            border: "0.5px solid #999",
                                            padding: "5px ",
                                            justifyContent: 'center',
                                            display: "flex"
                                          }}>
                                            <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                        }
                                        {oneQuestionData.question_media_type == "image" &&
                                          <div style={{ textAlign: "center" }}
                                            onClick={() => {
                                            }}
                                          >

                                            <img
                                              src={oneQuestionData.question_media}
                                              style={{
                                                height: "150px",
                                                width: "392px",
                                                padding: "2px",
                                                objectFit: 'contain',
                                                border: "0.5px solid #999"
                                              }}
                                            />
                                          </div>
                                        }
                                        {oneQuestionData.question_media_type == "audio" &&
                                          <div style={{ cursor: "pointer" }} onClick={() => {
                                          }}>
                                            <audio controls>
                                              {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                              <source src={`${oneQuestionData.question_media}`} type="audio/mpeg" />
                                              <source src={`${oneQuestionData.question_media}`} type="audio/mp3" />
                                              <source src={`${oneQuestionData.question_media}`} type="audio/wav" />
                                              <source src={`${oneQuestionData.question_media}`} type="audio/ogg" />
                                              Your browser does not support the audio element.
                                            </audio>
                                            {/* <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                style={{ width: "20px", height: "20px" }}
                              /> */}
                                          </div>
                                        }
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div className={oneQuestionData.answer_media_type == "image" ? "col-md-12 ms-5" : "col-md-4"}
                                  style={{ padding: "0" }}>
                                  <div
                                    className={oneQuestionData.answer_media_type == "image" ? "row" : ""}
                                    style={{
                                    }} >
                                    <AnswerBlock index={1}
                                    DataValueText={oneQuestionData.option_1_text}
                                    DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
                                    <AnswerBlock index={2}
                                    DataValueText={oneQuestionData.option_2_text}
                                    DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
                                    <AnswerBlock index={3}
                                    DataValueText={oneQuestionData.option_3_text}
                                    DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
                                    <AnswerBlock index={4} 
                                    DataValueText={oneQuestionData.option_4_text}
                                    DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

              }
            </>
            :
            <>
              <div>
                {Object.values(QuestionsData).map((oneQuestionData, index) => (
                  <section key={index} style={{
                    pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                  }}>
                    <div className="row"
                      style={{
                        border: "1px solid #999",
                        borderRadius: "5px",
                        margin: "5px",
                        padding: "10px",
                        backgroundColor: "#fff",
                      }}>
                      <div className="col-sm-7"
                        style={{ padding: "5px 0 0 0" }}
                      >
                        <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                          {oneQuestionData.question_number}.
                        </h3>
                        <div>
                          Question.
                          <input
                            className="p-0 px-2 col-11 input-common-121"
                            value={oneQuestionData.question}
                            onChange={(event) => {
                              onChangeFunction("question", event.target.value, index)
                            }}
                            placeholder="Question"
                          />
                        </div>
                        <div>
                          <div style={{ fontSize: "16px", padding: "3px" }}> Desc:
                            {questype.map((item1, index1) => (
                              <span key={index1}
                                style={{ fontWeight: oneQuestionData.question_description_type == item1 ? "bold" : "400" }}
                                onClick={() => { onChangeFunction("question_description_type", item1, index) }}>{item1} </span>
                            ))}
                          </div>
                          {oneQuestionData.question_description_type !== "none" &&
                            <>
                              <OptionView
                                data_type={oneQuestionData.question_description_type}
                                value={oneQuestionData.question_description}
                                index={index}
                                type={"question_description"}
                                placeholder={"Question Description"}
                                que={true}
                                onChangeFunction={onChangeFunction}
                              />
                            </>
                          }
                        </div>
                        <div>
                          <div style={{ fontSize: "16px", padding: "3px" }}>
                            Media:
                            {questype.map((item1, index1) => (
                              <span key={index1}
                                style={{ fontWeight: oneQuestionData.question_media_type == item1 ? "bold" : "400" }}
                                onClick={() => { onChangeFunction("question_media_type", item1, index) }}>{item1} </span>
                            ))}
                          </div>
                          <OptionView
                            data_type={oneQuestionData.question_media_type}
                            value={oneQuestionData.question_media}
                            index={index}
                            type={"question_media"}
                            placeholder={"Question Media"}
                            que={true}
                            onChangeFunction={onChangeFunction}
                          />
                          {
                            ((oneQuestionData.question_media_type == "image" && oneQuestionData.question_description_type == "audio")
                              ||
                              (oneQuestionData.question_media_type == "audio" && oneQuestionData.question_description_type == "image")
                            )
                            &&
                            <input
                              className="p-0 px-2 col-11 input-common-121"
                              value={oneQuestionData.question_text}
                              onChange={(event) => {
                                onChangeFunction("question_text", event.target.value, index)
                              }}
                              placeholder="Question Text"
                            />
                          }
                          {/* <OptionView
                            data_type={oneQuestionData.question_text}
                            value={oneQuestionData.question_text}
                            index={index}
                            type={"question_text"}
                            placeholder={"Question Text"}
                            que={true}
                            onChangeFunction={onChangeFunction}
                          /> */}
                          <div style={{ display: "inline-block", }}
                            onClick={() => {
                              var questionData = {
                                "question_number": oneQuestionData.question_number,
                                "question": oneQuestionData.question,
                                "question_description": oneQuestionData.question_description,
                                "question_description_type": oneQuestionData.question_description_type,
                                "question_media": oneQuestionData.question_media,
                                "question_media_type": oneQuestionData.question_media_type,
                                "option_1": oneQuestionData.option_1,
                                "option_2": oneQuestionData.option_2,
                                "option_3": oneQuestionData.option_3,
                                "option_4": oneQuestionData.option_4,
                                "answer_description": oneQuestionData.answer_description,
                                "question_text": oneQuestionData.question_text,
                                "correct_answer": oneQuestionData.correct_answer,
                                "answer_media_type": oneQuestionData.answer_media_type,
                              }
                              navigator.clipboard.writeText(JSON.stringify(questionData))
                              // setEditQuestion(false)
                              setToastText("Question Copied")
                              setToastActive(true)
                              setTimeout(() => {
                                setToastActive(false)
                              }, 1000)
                            }}
                          >Copy Question&nbsp;{toastActive && toastText}
                          </div>
                          <div style={{ display: "inline-block", }}
                            onClick={async () => {
                              var pasteValue = await navigator.clipboard.readText()
                              if (pasteValue[0] == "{") {
                                var parseValue = JSON.parse(pasteValue)
                                setQuestionsData({
                                  ...QuestionsData,
                                  [`${Object.keys(QuestionsData)[index]}`]: {
                                    "question_number": `${parseValue.question_number}`,
                                    "question": `${parseValue.question}`,
                                    "question_description": `${parseValue.question_description}`,
                                    "question_description_type": `${parseValue.question_description_type}`,
                                    "question_media": `${parseValue.question_media}`,
                                    "question_media_type": `${parseValue.question_media_type}`,
                                    "option_1": `${parseValue.option_1}`,
                                    "option_2": `${parseValue.option_2}`,
                                    "option_3": `${parseValue.option_3}`,
                                    "option_4": `${parseValue.option_4}`,
                                    "answer_description": `${parseValue.answer_description}`,
                                    "correct_answer": `${parseValue.correct_answer}`,
                                    "answer_media_type": `${parseValue.answer_media_type}`,
                                  }
                                })
                              }
                            }}
                          >&nbsp;Paste Question&nbsp;{toastActive && toastText}
                          </div>
                        </div>

                      </div>
                      <div className="col-sm-5 answer-style">
                        {questype.map((item1, index1) => (
                          <span key={item1}>
                            {item1 !== "none" &&
                              <span
                                style={{ fontWeight: oneQuestionData.answer_media_type == item1 ? "bold" : "400" }}
                                onClick={() => { onChangeFunction("answer_media_type", item1, index) }}>{item1} </span>
                            }
                          </span>
                        ))}
                        <br />
                        <div style={{ display: "inline" }}>
                          <div style={{ display: "inline-block" }}>
                            <span className={oneQuestionData.correct_answer == "option 1" ? "option-correct" : "option-wrong"}
                              onClick={() => {
                                onChangeFunction("correct_answer", "option 1", index)
                              }}
                            >
                              &nbsp;1&nbsp;
                            </span>
                          </div>
                          <div style={{ display: "inline-block" }}>
                            <OptionView
                              data_type={oneQuestionData.answer_media_type}
                              value={oneQuestionData.option_1}
                              index={index}
                              type={"option_1"}
                              placeholder={"Option 1"}
                              que={false}
                              onChangeFunction={onChangeFunction}
                            />
                          </div>
                        </div>
                        <br />
                        <div style={{ display: "inline" }}>
                          <div style={{ display: "inline-block" }}>

                            <span className={oneQuestionData.correct_answer == "option 2" ? "option-correct" : "option-wrong"}
                              onClick={() => {
                                onChangeFunction("correct_answer", "option 2", index)
                              }}
                            >
                              &nbsp;2&nbsp;
                            </span>
                          </div>
                          <div style={{ display: "inline-block" }}>

                            <OptionView
                              data_type={oneQuestionData.answer_media_type}
                              value={oneQuestionData.option_2}
                              index={index}
                              type={"option_2"}
                              placeholder={"Option 2"}
                              que={false}
                              onChangeFunction={onChangeFunction}
                            />
                          </div>
                        </div>
                        <br />
                        <div style={{ display: "inline" }}>
                          <div style={{ display: "inline-block" }}>
                            <span className={oneQuestionData.correct_answer == "option 3" ? "option-correct" : "option-wrong"}
                              onClick={() => {
                                onChangeFunction("correct_answer", "option 3", index)
                              }}
                            >
                              &nbsp;3&nbsp;
                            </span>
                          </div>
                          <div style={{ display: "inline-block" }}>
                            <OptionView
                              data_type={oneQuestionData.answer_media_type}
                              value={oneQuestionData.option_3}
                              index={index}
                              type={"option_3"}
                              placeholder={"Option 3"}
                              que={false}
                              onChangeFunction={onChangeFunction}
                            />
                          </div>
                        </div>
                        <br />
                        <div style={{ display: "inline" }}>
                          <div style={{ display: "inline-block" }}>

                            <span className={oneQuestionData.correct_answer == "option 4" ? "option-correct" : "option-wrong"}
                              onClick={() => {
                                onChangeFunction("correct_answer", "option 4", index)
                              }}
                            >
                              &nbsp;4&nbsp;
                            </span>
                          </div>
                          <div style={{ display: "inline-block" }}>
                            <OptionView
                              data_type={oneQuestionData.answer_media_type}
                              value={oneQuestionData.option_4}
                              index={index}
                              type={"option_4"}
                              placeholder={"Option 4"}
                              que={false}
                              onChangeFunction={onChangeFunction}
                            />
                          </div>
                        </div>
                        <input
                          className="p-0 px-2 col-11 input-common-121"
                          value={oneQuestionData.answer_description}
                          onChange={(event) => {
                            onChangeFunction("answer_description", event.target.value, index)
                          }}
                          placeholder="Answer Description"
                        />
                      </div>
                    </div>
                  </section>
                ))}
                {(db_slug == slugs.db_slug_daily_exam || db_slug == slugs.db_slug_chapter_wise_exam || db_slug == slugs.db_slug_question_bank) &&
                  <div>
                    <div className="row center m-2">
                      <div className=" col-3" style={{ padding: "0 10px" }}>
                        <div className="sign-in-button-4"
                          onClick={() => {
                            setQuestionsData({
                              ...QuestionsData,
                              [`item-${Object.keys(QuestionsData).length}`]: {
                                "question_number": `${Object.keys(QuestionsData).length + 1}`,
                                "question": "",
                                "question_description": "",
                                "question_description_type": "text",
                                "question_media": "",
                                "question_media_type": "none",
                                "option_1": "",
                                "option_2": "",
                                "option_3": "",
                                "option_4": "",
                                "answer_description": "",
                                "correct_answer": "option 1",
                                "answer_media_type": "text",
                              }
                            })
                          }}
                        >
                          Add Question
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </>
          }

        </div>
      </div>

      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Make a copy to:</center></h4>
          <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"All Exam"} />
          <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Batch Wise Exam"} />
          <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Demo Exam"} />
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  if (CopyTo == "Batch Wise Exam")
                    EditInfo("", slugs.db_slug_daily_exam, slugs.daily_exam_details)
                  else if (CopyTo == "Student Exam")
                    EditInfo("", slugs.db_slug_buy_exam, slugs.buy_exam_details)
                  else if (CopyTo == "All Exam")
                    EditInfo("", slugs.db_slug_paid_exam, slugs.exam_details)
                  else
                    EditInfo("", slugs.db_slug_demo_exam, slugs.demo_exam_details)

                }}
              >
                Make a copy
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        QuestionsEditMode &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setQuestionsEditMode(false)
                    setQuestionsEditData([])
                    setQuestionsEditKey("")
                  }}
                >X</div>
                <ExamQuestionModal
                  oneQuestionData={QuestionsEditData}
                  keyValue={QuestionsEditKey}
                  AllQuestionData={QuestionsData}
                  setAllQuestionData={setQuestionsData}
                  PostExam={EditInfo}
                  setEditQuestion={setQuestionsEditMode}
                />
              </div>
            </div>
          </div>
        </section>
      }


      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}





const AnswerBlock = ({ index, DataType, DataValue, correct_answer, option,DataValueText }) => {
  return (
    <div
      className={DataType == "image" ? "col-6" : "col-12"}
      style={{
        borderLeft: option == correct_answer ? "2px solid green" : 0,
        display: 'flex',
        padding: "5px 10px",
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}>
        <div style={{
          border: option == correct_answer ? "2px solid green" : "2px solid black",
          backgroundColor: option == correct_answer ? "green" : "#fff",
          color: option == correct_answer ? "#fff" : "#000",
          borderRadius: "14px",
          width: "20px",
          height: "20px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>
      <div style={{ fontSize: "16px" }} className="passive-option2">
        {/* {DataType == "text" &&
          `${DataValue}`
        } */}
        {(DataType == "text" || DataType == "choose_correct") &&
          <span  >
            <div dangerouslySetInnerHTML={{ __html: DataValue.replace(DataValueText, `<u>${DataValueText}</u>`) }}></div>
            {/* {DataValue}  */}
          </span>
        }
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                width: "120px",
                border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <audio controls>
              {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
              <source src={`${DataValue}`} type="audio/mpeg" />
              <source src={`${DataValue}`} type="audio/mp3" />
              <source src={`${DataValue}`} type="audio/wav" />
              <source src={`${DataValue}`} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>
  )
}